import dayjs from "dayjs";
var utc = require('dayjs/plugin/utc') // dependent on utc plugin
var timezone = require('dayjs/plugin/timezone')
dayjs.extend(utc)
dayjs.extend(timezone)

const formatDate = {

filters: {
    formatDate(value){
        return dayjs(value).tz('America/Fortaleza').format('DD/MM/YYYY')
    }
}

}

export default formatDate