<template>
  <v-app class="color">
    <NavBar />
    <v-main>
      <router-view />
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import NavBar from "@/components/NavBar";
import { getProfile } from "../mixins";

import Footer from "@/views/Footer";
export default {
  name: "Home",
  mixins: [getProfile],

  components: { Footer, NavBar },
  data: () => {
    return {
      salePerson: null,
      role: null,
    };
  },
  created() {
    this.getProfile();
    this.getPermission();
  },
  methods: {
        async getPermission() {
            try {
                const response = await this.$http.get("profile");
                this.role = response.data.role;
                    if(this.role === 'seller' && this.$router.history.current.fullPath !== '/saleperson'){
                    this.$router.push("/saleperson");
                  }
            } catch (error) {

                if (error.response.status === 500) {
                    window.localStorage.removeItem("token");
                    this.$router.push("/");
                } else {
                    window.localStorage.removeItem("token");
                    this.$router.push("/");
                    this.$toast.error("Falha no login");
                }
            }
        },
  }
};
</script>
