const PeriodInProgress = {
    methods: {
        async getPeriodInProgress() {
            try {
                this.loading = true
                const { data, status } = await this.$http.get(`/saleperiod/inprogress`);
                if (status == '200') {
                    this.loading = false
                    this.periodInProgress = data;
                } else {
                    throw new Error()
                }
            } catch (error) {
                this.$toast.error("Não foi possivel carregar as informações!!!");
            }
        }
    }

}
export default PeriodInProgress;