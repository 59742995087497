<template>
  <div>
    <v-navigation-drawer v-model="active" app>
      <v-toolbar color="#1565C0" dark>
        <v-spacer></v-spacer>
        <v-toolbar-title> LINKCARIRI ® </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-list dense nav v-if="role === 'admin' || role === 'coord'"> 
        <v-subheader>Home</v-subheader>
        <v-list-item 
          v-for="item in items_dash"
          :key="item.title"
          link
          class="mb-0"
          :to="{ name: item.link}"
        >

          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

            <v-list dense nav v-if="role === 'coord'"> 
        <v-subheader>Coordenador</v-subheader>
        <v-list-item 
          v-for="item in items_coord"
          :key="item.title"
          link
          class="mb-0"
          :to="{ name: item.link}"
        >

          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-list  dense nav v-if="role === 'seller'">
        <v-subheader>Vendedor</v-subheader>
        <v-list-item 
          v-for="item in items_seller"
          :key="item.title"
          link
          class="mb-0"
          :to="{ name: item.link}"
        >

          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <!-- menu register -->
      <v-list  dense nav v-if="role === 'admin' || role === 'coord'">
        <v-subheader >Cadastros</v-subheader>
        <v-list-item
        
          v-for="item in items_admin"
          :key="item.title"
          link
          class="mb-0"
          :to="{ name: item.link }"
          
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-list dense nav v-if="role !== 'seller' || role === 'rh' ">
        
      <v-subheader>Relatórios</v-subheader>
        <v-list-item
          v-for="item in items_report"
          :key="item.title"
          link
          class="mb-0"
          :to="item.link"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        
      </v-list>
    </v-navigation-drawer>
    <v-app-bar color="primary" fixed dark app>
      <v-app-bar-nav-icon @click.stop="active = !active"></v-app-bar-nav-icon>

      <v-spacer></v-spacer>
      <v-menu offset-y :nudge-bottom="3" transition="scale-transition">
        <template v-slot:activator="{ on: menu, attrs }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on: tooltip }">
              <v-toolbar-items>
                <v-btn icon v-bind="attrs" v-on="{ ...tooltip, ...menu }">
                  <v-icon x-large>mdi-account-circle</v-icon>
                </v-btn>
              </v-toolbar-items>
            </template>
            <span>Perfil</span>
          </v-tooltip>
        </template>
        <v-list>
                              <v-list-item to="profile">
            <v-list-item-title >Alterar senha</v-list-item-title>
          </v-list-item>

          <v-list-item @click="logout()">
            <v-list-item-title >Sair</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    
  </div>
</template>

<script>

export default {

  data: () => {
    return {
      role: null,
      active: true,
      items_coord: [

        {
          icon: "mdi-view-dashboard",
          title: "Coordenador",
          link: "coordDASH"
        },
      ],
      items_dash: [
        {
          icon: "mdi-view-dashboard",
          title: "Vendas",
          link: "DashBoard"
        },
        {
          icon: "mdi-view-dashboard",
          title: "Vendedores",
          link: "SellerDASH"
        },
      ],
      items_seller: [
                  {
          icon: "mdi-poll",
          title: "Vendedor",
          link: "vendedor"
        }
      ],
      items_report: [
        {
          icon: "mdi-google-spreadsheet",
          title: "Bonificações",
          link: "report"
        }
      ],
      items_admin: [
        // {
        //   comercial: [
        //     { title: "Comercial - Marketing", link: "comercial" },
        //     { title: "Dashboard Clientes", link: "dashboard-clientes" },
        //   ],
        // },

        {
          icon: "mdi-account",
          title: "Usuarios",
          link: "Usuarios"
        },
        {
          icon: "mdi-bullseye-arrow",
          title: "Metas",
          link: "Metas"
        },
        {
          icon: "mdi-account-group",
          title: "Times",
          link: "Times"
        },
        {
          icon: "mdi-package-variant-closed",
          title: "Produtos",
          link: "Produtos"
        },
        {
          icon: "mdi-account-multiple",
          title: "Vendedores",
          link: "Vendedores"
        },
        {
          icon: "mdi-currency-usd",
          title: "Valor Bonificação",
          link: "bonus"
        }
        ,
        {
          icon: "mdi-calendar",
          title: "Periodos",
          link: "Periodos"
        }
        
      ],
    };
  },
  created() {
    this.getPermission();
  },
  methods: {
        logout() {
      localStorage.removeItem("token");
      this.$router.push("/");
    },
    async getPermission() {
            try {
                const response = await this.$http.get("profile");
                this.role = response.data.role;
            } catch (error) {

                if (error.response.status === 500) {
                    window.localStorage.removeItem("token");
                    this.$router.push("/");
                } else {
                    window.localStorage.removeItem("token");
                    this.$router.push("/");
                    this.$toast.error("Falha no login");
                }
            }
        },
  }
};
</script>

<style>
</style>