const getProfile = {
    methods: {
        async getProfile() {
            try {
                const response = await this.$http.get("profile");
                this.salePerson = response.data.fk_vendedor;
            } catch (error) {

                if (error.response.status === 500) {
                    window.localStorage.removeItem("token");
                    this.$router.push("/");
                } else {
                    this.$toast.error("Falha no login");
                    window.localStorage.removeItem("token");
                    this.$router.push("/");
                }
            }
        },
    },
}

export default getProfile;